import React, {useState, useEffect} from 'react';
import {Input, Button } from 'react-materialize';
import { SwatchesPicker } from 'react-color';
import model from '../../models/models';
//import bugSnagHelper from '../../utils/bugSnagHelper'

const ContextDetails= props => {
  console.log('in ContextDetails props is ', props);
  const [context_value, setContext_value] = useState('');
  const [swatchStyle, setSwatchStyle] = useState( {backgroundColor: '#0A838E' });
  const [color_value, setColor_value] = useState('#0A838E');
  const [displayColorPicker,setDisplayColorPicker] = useState( false);
 
  if(props.planzu && props.planzu.contexts && props.planzu.contexts.length > 0){
    const context = props.planzu.contexts.find(contextItem =>{
      return contextItem.context_id === props.match.params.id;
    });
    console.log('in contextDetails context is ', context);
    if(context_value !== context.name){
      setContext_value(context.name);
      setColor_value(context.color || '#0A838E');
      setSwatchStyle({backgroundColor: context.color ||'#0A838E' })
    }
  }
  function onContextNameChange(value){
   setContext_value(value)
  }
  function onColorSelect(color) {
    let contextProperty = context;
    contextProperty.color = color.hex;
    setContext_value(context.name);
    setColor_value(context.color || '#0A838E');
    setSwatchStyle({backgroundColor: context.color ||'#0A838E' })
  }
  function onSwatchClick(){
    console.log('in onSwatchClick displayColorPicker is ', displayColorPicker);
    setDisplayColorPicker(true);
  }
  function onCoverClose(){
    setDisplayColorPicker(false);
  }
 
  function onDeleteContext(e){
    e.stopPropagation();
    e.preventDefault();  
    //console.log('in onDeleteContext');
    props.deleteContext(context.context_id);
  }
  function onUpdateContext(e){
    //console.log('in onUpdateContext()');
    e.stopPropagation();
    e.preventDefault();

    if(context.name && context.name.length > 0 &&
      context.color && context.color.length > 0) {

      let updatedContext = new model.Context();
      updatedContext.name = context_value;
      updatedContext.color = color_value;
      updatedContext.context_id = context.context_id;
      updatedContext.old_name = context.name
      props.updateContext(updatedContext);
    }
  }
  function handleErrors(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
  }

  return (  
    <div className="single-context">
      <form className="form-planzu" >
        
        <div className="planzu-field-label">Context</div>
        <Input className="planzu-input" s={12} value={context_value} onChange={(val)=>onContextNameChange(val)} />
        <div className="planzu-field-label">Color Picker</div>
        <a className="swatch" tabIndex={-42} onClick={onSwatchClick}>
          <div className="color" style={swatchStyle} />
          <span className="btn btn-color grey lighten-4 cyan-text text-darken-4">Set Color</span>
        </a>
        <div>
          {
            displayColorPicker ? 
              <div className="popover">
                <a tabIndex={-42} className="cover" onClick={onCoverClose}> </a>
                <SwatchesPicker color={context.color} onChange={onColorSelect} triangle="hide" />
              </div> :
              null
          }
        </div>
        <Button className="btn-planzu-half red lighten-3"  onClick={onDeleteContext}>
          Delete
        </Button>
        <Button className="btn-planzu-half cyan darken-4 white-text" onClick={onUpdateContext}>
          Save
        </Button>
      </form>
    </div>
  )

}

export default ContextDetails;