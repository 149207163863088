import uuidv4 from 'uuid/v4';

import {
  INIT_PLANZU,
  LOAD_PLANZU,
  READ_REMOTE_PLANZU,
  UPDATE_LOCAL_PLANZU,
  TEST_PLANZU, 
  ADD_WORKSET, 
  UPDATE_WORKSET, 
  DELETE_WORKSET, 
  ADD_CONTEXT,
  READ_CONTEXT,
  UPDATE_CONTEXT, 
  DELETE_CONTEXT,
  REORDER_CONTEXTS
  } from './actionTypes'; 
import PlanzuDal from '../data/planzuDal';
import setContextGroups from '../utils/setContextGroups';
import setContextSuggestions from '../utils/setContextSuggestions';

//import PlanzuService from '../services/planzuService';
//const host = 'http://localhost:3005'; //'https://www.planzu.com'; //

const mixPlanzu = (raw_planzu) =>{
  //console.log('in mixPlanzu raw_planzu is ', raw_planzu )
  let mixedPlanzu = null;
  if(raw_planzu){
    const contextGroups = setContextGroups(raw_planzu.worksets);
    const suggestions = setContextSuggestions(raw_planzu.contexts);
    mixedPlanzu = Object.assign(raw_planzu, {contextGroups:contextGroups}, {suggestions:suggestions});
  }
  return mixedPlanzu;
}  
export const initPlanzu = () =>{
  const planzuDal = new PlanzuDal();
  planzuDal.initialize();
  return {
    type: INIT_PLANZU,
    payload: 'initialized'
  }
}
export const loadPlanzu = () => { 
  //console.log('in planzuActions.loadPlanzu ');
  const planzuDal = new PlanzuDal();
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);
  return {
    type: LOAD_PLANZU,
    payload: mixedPlanzu
  }
}
export const readRemotePlanzu = () => {
  //console.log('in planzuActions.addWorkset')
  const planzuDal = new PlanzuDal();

  try{ 
    const planzu = planzuDal.getPlanzu();
    const remote_planzuPromise = planzuDal.readRemotePlanzu();
    return {
      type: READ_REMOTE_PLANZU,
      payload: remote_planzuPromise.then(remote_planzu_result =>{
        let mixed_planzu = mixPlanzu(planzu)
        //console.log('in readRemotePlanzu read remote result mixed_planzu is ', mixed_planzu);
        const remote_mixed_planzu = mixPlanzu(remote_planzu_result);
        //console.log('in readRemotePlanzu read remote result remote_mixed_planzu is ', remote_mixed_planzu);
        mixed_planzu.remote_planzu = remote_mixed_planzu;
        //console.log('in readRemotePlanzu read remote result mixed_planzu is ', mixed_planzu);
        return mixed_planzu
      })
    }
  } catch(error){
    console.log('error in planzuActions addWorkset', error)
  }
}
export const updateLocalPlanzu = (planzu) =>{
  const planzuDal = new PlanzuDal();
  planzuDal.updateLocalPlanzu(planzu);
  planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu)
  return{
    type: UPDATE_LOCAL_PLANZU,
    payload: mixedPlanzu
  }  
}
export const testPlanzu =() =>{
  return {
    type: TEST_PLANZU,
    payload: 'excellent'
  }
}
export const addWorkset = (newWorkset ) => {
  newWorkset.workset_id = uuidv4();
  const planzuDal = new PlanzuDal();
  planzuDal.createWorkset(newWorkset);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);

  return{
    type: ADD_WORKSET,
    payload: mixedPlanzu
  }
}
export const readWorkset = (worksetId)=>{
  console.log('in planzuActions readWorkset worksetId is ', worksetId);
}
export const updateWorkset = (updatedWorkset) =>{
  //console.log('updateWorkset with ', updatedWorkset);
  const planzuDal = new PlanzuDal();  
  planzuDal.updateWorkset(updatedWorkset);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);
  return{
    type: UPDATE_WORKSET,
    payload: mixedPlanzu
  }
}
export const deleteWorkset = (worksetId ) => {
  const planzuDal = new PlanzuDal();
  planzuDal.deleteWorkset(worksetId);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu)
  return{
    type: DELETE_WORKSET,
    payload: mixedPlanzu
  } 
}
export const addContext = (newContext) => {
  newContext.context_id = uuidv4();
  const planzuDal = new PlanzuDal();
  planzuDal.createContext(newContext);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);

  return{
    type: ADD_CONTEXT,
    payload: mixedPlanzu
  }
}
export const readContext = (contextName) =>{
  //console.log('readContext with ', contextName);
  const planzuDal = new PlanzuDal();  
  const foundContext = planzuDal.readSingleContextByName(contextName);

  return{
    type: READ_CONTEXT,
    payload: foundContext
  }
}
export const updateContext = (updatedContext) =>{
  //console.log('updateContext with ', updatedContext);
  const planzuDal = new PlanzuDal();  
  planzuDal.updateContext(updatedContext);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);
  return{
    type: UPDATE_CONTEXT,
    payload: mixedPlanzu
  }
}
export const deleteContext = (contextId) => {
  //console.log('in planzuActions.deleteContext', contextId);
  const planzuDal = new PlanzuDal();
  planzuDal.deleteContext(contextId);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);
  return{
    type: DELETE_CONTEXT,
    payload: mixedPlanzu
  }
}
export const reorderContexts = (old_index, new_index) => {
  const planzuDal = new PlanzuDal();
  planzuDal.reorderContexts(old_index, new_index);
  const planzu = planzuDal.getPlanzu();
  const mixedPlanzu = mixPlanzu(planzu);
  return {
    type: REORDER_CONTEXTS,
    paylad: mixedPlanzu
  }
}