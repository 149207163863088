import React  from 'react';

const arrow = `\u2b91`;
const Tree = (props) => {
  return(
    <div className="m-l-20">
      <li key={props.workset_id}>
        <span className="m-l-10 m-r-10">{arrow}</span>
        <a className="fs-24"  href={'/workset/' + props.branch.workset_id} >
          {props.branch.work_description}
        </a>
      </li>
      {
        props.branch.dependents ?
        props.branch.dependents.map(dependent =>(
          <Tree 
            key={dependent.workset_id}
            workset_id={dependent.workset_id} 
            branch={dependent} 
            workset={props.workset}
          />)) : null  
      }
    </div>
  )
}
export default  Tree;
