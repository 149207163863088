const setContextSuggestions = (contexts) => {
  let contextSuggestions={}
  if(contexts && contexts.length > 0 ) {
    contextSuggestions =  contexts.reduce((obj, item) => {
      obj[item['name']] = null
      return obj
    }, {});

  }
  return contextSuggestions;
}
export default setContextSuggestions;