import bugSnagHelper from '../utils/bugSnagHelper';
import planzuServices from '../services/planzuServices';

export default class PlanzuDal{
  constructor(){
    this.getData = this.getData.bind(this);
    this.setData = this.setData.bind(this);
    this.getPlanzu = this.getPlanzu.bind(this);
    this.readRemotePlanzu = this.readRemotePlanzu.bind(this);
    this.updateRemotePlanzu = this.updateRemotePlanzu.bind(this);
    this.updateLocalPlanzu = this.updateLocalPlanzu.bind(this);
    this.reset = this.reset.bind(this);
    this.initialize = this.initialize.bind(this);
    this.createWorkset = this.createWorkset.bind(this);
    this.updateWorkset = this.updateWorkset.bind(this);
    this.deleteWorkset = this.deleteWorkset.bind(this);
    this.createContext = this.createContext.bind(this);
    this.readSingleContextByName = this.readSingleContextByName.bind(this);
    this.readSingleContextById = this.readSingleContextById.bind(this);
    this.updateContext = this.updateContext.bind(this);
    this.deleteContext = this.deleteContext.bind(this);
    this.reorderContexts = this.reorderContexts.bind(this);
    this.betaSignup = this.betaSignup.bind(this);
  }
  getData(){
    const value =localStorage.getItem('planzuStorage');
    return value;
  }
  setData(){
    localStorage.setItem('planzuStorage', '');
    return true;
  }
  getPlanzu(){  

    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu)
    return planzu;   
  }

  readRemotePlanzu(){
    const access_token=localStorage.getItem('access_token'); 
    const user_profile=localStorage.getItem('user_profile');
    if(access_token && access_token){
      return planzuServices.getPlanzu(access_token, user_profile);
    }
  }
  updateRemotePlanzu(planzu){
    console.log('in planzuDal.updateRemotePlanzu', planzu);
    const access_token=localStorage.getItem('access_token'); 
    const user_profile=localStorage.getItem('user_profile');

    delete planzu.suggestions;
    delete planzu.contextGroups;
    delete planzu.suggestions;
    planzu.timestamp = Date.now();

    if(access_token){
      const planzuPromise=planzuServices.updatePlanzu(access_token, user_profile, planzu);
      planzuPromise.then( incoming_planzu =>{
        //console.log('in planzuDal.getRemotePlanzu planzu promise success ', incoming_planzu);
        //this.setState({planzu:incoming_planzu});
      }).catch( error =>{
        console.log('in planzuDal.getRemotePlanzu planzu promise error ', error);
      });
    }
  
  
  }
  updateLocalPlanzu(updatedPlanzu){
    //console.log('in planzuDal.updateLocalPlanzu', updatedPlanzu);
    updatedPlanzu.timestamp = Date.now();
    localStorage.setItem('planzuStorage', JSON.stringify(updatedPlanzu));
  }
  reset(){
    const newPlanzuDoc = JSON.stringify({worksets:[], contexts:[]});
   // console.log('resetting Planzu'); 
   localStorage.setItem('planzuStorage', newPlanzuDoc);  
  }
  initialize(){
    let checkPlanzu =localStorage.getItem('planzuStorage');
    checkPlanzu = JSON.parse(checkPlanzu);
    if(!checkPlanzu){
      //console.log('initializing Planzu');
      const newPlanzuDoc = JSON.stringify({worksets:[], contexts:[], timestamp:Date.now()}); 
      localStorage.setItem('planzuStorage', newPlanzuDoc);
      this.getPlanzu();
    }
  }
  createWorkset(workset){
    console.log('from planzuDal.createWorkset');
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    planzu.worksets.push(workset);
    this.updateRemotePlanzu(planzu);
    localStorage.setItem('planzuStorage', JSON.stringify(planzu));
  }
  updateWorkset(updatedWorkset){
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    const index = planzu.worksets.findIndex(w => w.workset_id===updatedWorkset.workset_id);
    if(index > -1){
      planzu.worksets[index] = updatedWorkset;
    } else {
      //console.log('at planzuDal.updateWorkset did not update');
    }
    this.updateRemotePlanzu(planzu);
    localStorage.setItem('planzuStorage', JSON.stringify(planzu));
  }
  deleteWorkset(workset_id){
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    planzu.worksets = planzu.worksets.filter(w =>{return w.workset_id !== workset_id});
    this.updateRemotePlanzu(planzu);
    localStorage.setItem('planzuStorage', JSON.stringify(planzu));
  }
  createContext(context){
    try{
      const previousContext =this.readSingleContextByName(context.name);
      if(!previousContext || previousContext.length === 0){
        let planzu =localStorage.getItem('planzuStorage');
        planzu = JSON.parse(planzu);
        context.key = planzu.contexts.length + 1;
        planzu.contexts.push(context);
        this.updateRemotePlanzu(planzu);
        localStorage.setItem('planzuStorage', JSON.stringify(planzu)); 
      } else {
        return null;
      }
    } catch(error){
      bugSnagHelper(error);
    } 
  }
  readSingleContextByName(name){
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    const foundContext = planzu.contexts.filter(c =>{return c.name === name});
    return foundContext;
  }
  readSingleContextById(context_id){
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    const foundContext = planzu.contexts.filter(c =>{return c.context_id === context_id});
    return foundContext;
  }
  updateContext(updatedContext){
    //console.log('in planzuDal update Workset updatedContext is ', updatedContext);
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    const toBeUpdatedContext =this.readSingleContextById(updatedContext.context_id);
    //console.log('in planzuDal update Workset toBeUpdatedcontext is ', toBeUpdatedContext);
    const index = planzu.contexts.findIndex(c => c.context_id===updatedContext.context_id);
    if(index > -1){
      planzu.contexts[index] = updatedContext;
      //console.log('in planzuDal update Workset planzu.contexts[index] is ', planzu.contexts[index]);
    } 
    const updatedWorksets = planzu.worksets.map(w=>{
      if(w.context === toBeUpdatedContext[0].name){
        w.context = updatedContext.name;
      }
      return w;
    });
    planzu.worksets = updatedWorksets;
    this.updateRemotePlanzu(planzu);
    localStorage.setItem('planzuStorage', JSON.stringify(planzu));
  }
  reorderContexts(old_index, new_index) {
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    let reorderedContexts = planzu.contexts;
    if (new_index >= reorderedContexts.length) {
      var k = new_index - reorderedContexts.length + 1;
      while (k--) {
        reorderedContexts.push(undefined);
      }
    }
    reorderedContexts.splice(new_index, 0, reorderedContexts.splice(old_index, 1)[0]);
    reorderedContexts = reorderedContexts.map((rc,index)=>{ return Object.assign(rc, {key:index})});
    planzu.contexts = reorderedContexts;
    this.updateRemotePlanzu(planzu);
    localStorage.setItem('planzuStorage', JSON.stringify(planzu));
    this.getPlanzu();
  }
  deleteContext(context_id){
    let planzu =localStorage.getItem('planzuStorage');
    planzu = JSON.parse(planzu);
    const toBeDeletedContext =this.readSingleContextById(context_id);
    planzu.contexts = planzu.contexts.filter(w =>{return w.context_id !== context_id}); 
    const replacedWorksets = planzu.worksets.map(w=>{
      if(w.context === toBeDeletedContext[0].name){
        w.context = '@inbox';
      }
      return w;
    });
    planzu.worksets = replacedWorksets;
    this.updateRemotePlanzu(planzu);
    localStorage.setItem('planzuStorage', JSON.stringify(planzu));
    this.getPlanzu();
  }
  betaSignup(email, headline){
    planzuServices.betaSignup(email, headline);
  }
  sendComment(comment, headline){
    planzuServices.sendComment(comment, headline);
  }
}