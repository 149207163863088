import auth0 from 'auth0-js';
import queryString from 'query-string';
import history from '../components/history';
//import bugSnagHelper from '../utils/bugSnagHelper'


export default class Auth {

  constructor(variables){
    //console.log('in Auth.constructor variables are ', variables);
    this.myAuth0 = new auth0.WebAuth({
      domain: 'connectedreasoning.auth0.com',
      clientID: variables.client_id,
      redirectUri: variables.redirect_url,
      audience: '7VdAOXmCkZPpMgv60uWuw3iH4zQh6s4C',
      responseType: 'token id_token',
      scope: 'openid'
    });
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }
  login() {
    this.myAuth0.authorize();
  }
  handleAuthentication(locationhash) {  
    console.log('in  Auth.handleAuthentication');  
    const authResult = queryString.parse(locationhash);
    if (authResult && authResult.access_token && authResult.id_token) {
      const getProfilePromise = this.getProfile(authResult.access_token);
      getProfilePromise.then( profile=>{
        let expires_at = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.access_token);
        localStorage.setItem('id_token', authResult.id_token);
        localStorage.setItem('expires_at', expires_at);
        localStorage.setItem('user_profile', JSON.stringify(profile));
        history.replace('/');
        history.go(0);
      });

    } else  {
      console.log('error in handleAuthentication');
    }
  }
  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_profile');
    // navigate to the home route
    console.log('in logout exit. localStorage is ', localStorage);
    history.replace('/');
    history.go(0);
  }
  isAuthenticated() {
    // Check whether the current time is past the 
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    //console.log('in auth.getAccessToken ', accessToken);
    return accessToken;
  }

  getProfile(accessToken) {
    return new Promise((resolve, reject) => {
      this.myAuth0.client.userInfo(accessToken, (error, profile) => {
        if (profile) {
          this.userProfile = profile;
          resolve(profile);
        } else {
          console.log('userInfo fail error is ', error);
          reject(error);
        }
      });
    });
  }
}