  const luminanceByColor = (color) => { 
    let luminance = 0;
    const isHEX = color.indexOf("#") == 0;
    const isRGB = color.indexOf("rgb") == 0
    let m, r, g, b;
    
    if (isHEX) {
      m = color.substr(1).match(color.length == 7 ? /(\S{2})/g : /(\S{1})/g);
      if (m) {
        r = parseInt(m[0], 16);
        g = parseInt(m[1], 16);
        b = parseInt(m[2], 16);
      }
    }
    if (isRGB) {
      m = color.match(/(\d+){3}/g);
      if (m) {
        r = m[0];
        g = m[1];
        b = m[2];
      }
    }
    if (typeof r != "undefined") {
      luminance =  ((r*299)+(g*587)+(b*114))/1000;
    } 
    return luminance;
  }
  export default luminanceByColor;