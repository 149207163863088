import React  from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/home/home';
import WorksetDetails from './components/worksetDetails/worksetDetails';
import Contexts from './components/contexts/contexts';
import ContextDetails from './components/contextDetails/contextDetails';
import Menu from './components/menu/menu';
import Focus from './components/focus/focus';
import Goals from './components/goals/goals';
import Blog from './components/blog/blog';
import Help from './components/help/help';
import Calendar from './components/calendar/calendar';
import About from './components/about/about';
import Profile from './components/profile/profile';
import Splash from './components/splash/splash';
import ErrorPage from './components/errorpage/errorpage';
import bugSnagHelper from './utils/bugSnagHelper';

export default class App extends React.Component {
  constructor(props){
    super(props);
    //console.log('in App.constructor this.props is ', this.props);
    this.state={ 
      planzu: {}, 
      app:{}, 
    };
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.refresh = this.refresh.bind(this);
    this.headline = this.getHeadline();

  } 
  static getDerivedStateFromProps(nextProps, prevState){
    //console.log('in planzu.getDerivedStateFromProps  nextProps is ', nextProps);
    //console.log('in planzu.getDerivedStateFromProps  prevState is ', prevState);
    return{planzu: nextProps.planzu};
  }
  componentDidMount(){
    //console.log('in App.componentWicomponentDidMountllReceiveProps nextProps is ');
    this.props.initPlanzu();
    this.props.loadPlanzu();
  }
  getHeadline(){
    const headlines = [
      `Fulfill your destiny on the planet with Planzu`,
      `Here's what to do with everything. - Planzu`,
      `Turn your dreams into action with Planzu`,
      `Practice mind over matter with Planzu`
    ];
     const  random_index = Math.floor(Math.random() * headlines.length);
     return headlines[random_index];
  }  
  handleAuthentication({location}) {
    if (/access_token|id_token|error/.test(location.hash)) {
      this.props.auth.handleAuthentication(location.hash);
    }
  }
  refresh(){
      try {
        console.log('in app refresh');
        this.props.loadPlanzu();
      } catch(error){
        bugSnagHelper(error);
      }
  }
  render(){
    //console.log('in app.render this.props is ', this.props);
    //console.log('in app.render this.state is ', this.state);
    return(
      <div>
        <Menu auth={this.props.auth} />
        <BrowserRouter>
          <div className="main">
            <Route 
              exact path="/" render={(props) => {
                return this.props.auth.isAuthenticated() ? (
                  <Home 
                    auth={this.props.auth} 
                    planzu={this.state.planzu} 
                    loadPlanzu={this.props.loadPlanzu}
                    readRemotePlanzu={this.props.readRemotePlanzu}
                    updateRemotePlanzu={this.props.updateRemotePlanzu}
                    updateLocalPlanzu={this.props.updateLocalPlanzu}
                    addWorkset={this.props.addWorkset}
                    updateWorkset={this.props.updateWorkset}
                    onWorksetChange={this.onWorksetChange}
                    deleteWorkset={this.props.deleteWorkset} 
                    addContext={this.props.addContext}
                    updateContext={this.props.updateContext}
                    deleteContext={this.props.deleteContext}
                    reorderContexts={this.props.reorderContexts}
                    {...props} 
                  />) : (
                    <Splash auth={this.props.auth} headline={this.headline} {...props} />
                )  
              }}
            />
            <Route 
              path="/home" render={(props) => {
                return this.props.auth.isAuthenticated() ? (
                  <Home 
                    auth={this.props.auth} 
                    planzu={this.state.planzu} 
                    loadPlanzu={this.props.loadPlanzu}
                    readRemotePlanzu={this.props.readRemotePlanzu}
                    updateRemotePlanzu={this.props.updateRemotePlanzu}
                    updateLocalPlanzu={this.props.updateLocalPlanzu}
                    addWorkset={this.props.addWorkset}
                    updateWorkset={this.props.updateWorkset}
                    onWorksetChange={this.onWorksetChange}
                    deleteWorkset={this.props.deleteWorkset} 
                    addContext={this.props.addContext}
                    updateContext={this.props.updateContext}
                    deleteContext={this.props.deleteContext}
                    reorderContexts={this.props.reorderContexts}
                    {...props} 
                  />) : (
                    <Splash auth={this.props.auth} headline={this.headline} {...props} />
                )               
              }}
            />
            {/*
            <Route 
              path="/workset/:id" render={(props) => {
                return (<WorksetDetails 
                  auth={this.props.auth} 
                  planzu={this.state.planzu} 
                  readRemotePlanzu={this.props.readRemotePlanzu}
                  updateRemotePlanzu={this.props.updateRemotePlanzu}
                  loadPlanzu={this.props.loadPlanzu}
                  addWorkset={this.props.addWorkset}
                  updateWorkset={this.props.updateWorkset}
                  onWorksetChange={this.onWorksetChange}
                  deleteWorkset={this.props.deleteWorkset} 
                  addContext={this.props.addContext}
                  updateContext={this.props.updateContext}
                  deleteContext={this.props.deleteContext}
                  reorderContexts={this.props.reorderContexts}
                  {...props} 
                />) 
              }}
            />
            <Route 
              exact path="/contexts" render={(props) => {
                return  (<Contexts 
                  auth={this.props.auth} 
                  planzu={this.state.planzu} 
                  loadPlanzu={this.props.loadPlanzu}
                  readRemotePlanzu={this.props.readRemotePlanzu}
                  updateRemotePlanzu={this.props.updateRemotePlanzu}
                  addWorkset={this.props.addWorkset}
                  updateWorkset={this.props.updateWorkset}
                  onWorksetChange={this.onWorksetChange}
                  deleteWorkset={this.props.deleteWorkset} 
                  addContext={this.props.addContext}
                  updateContext={this.props.updateContext}
                  deleteContext={this.props.deleteContext}
                  reorderContexts={this.props.reorderContexts}              
                  {...props} 
                />) 
              }}
            />
            <Route 
              exact path="/context/:id" render={(props) => {
                return (<ContextDetails 
                  auth={this.props.auth} 
                  planzu={this.state.planzu} 
                  loadPlanzu={this.props.loadPlanzu}
                  readRemotePlanzu={this.props.readRemotePlanzu}
                  updateRemotePlanzu={this.props.updateRemotePlanzu}
                  addWorkset={this.props.addWorkset}
                  updateWorkset={this.props.updateWorkset}
                  onWorksetChange={this.onWorksetChange}
                  deleteWorkset={this.props.deleteWorkset} 
                  addContext={this.props.addContext}
                  updateContext={this.props.updateContext}
                  deleteContext={this.props.deleteContext}
                  reorderContexts={this.props.reorderContexts}              
                  {...props} 
                />) 
              }}
            />
            <Route 
              exact path="/calendar" render={(props) => {
                return (<Calendar 
                  auth={this.props.auth} 
                  planzu={this.state.planzu} 
                  loadPlanzu={this.props.loadPlanzu}
                  readRemotePlanzu={this.props.readRemotePlanzu}
                  updateRemotePlanzu={this.props.updateRemotePlanzu}
                  addWorkset={this.props.addWorkset}
                  updateWorkset={this.props.updateWorkset}
                  onWorksetChange={this.onWorksetChange}
                  deleteWorkset={this.props.deleteWorkset} 
                  addContext={this.props.addContext}
                  updateContext={this.props.updateContext}
                  deleteContext={this.props.deleteContext}
                  reorderContexts={this.props.reorderContexts}              
                  {...props} 
                />) 
              }}
            />
            <Route 
              exact path="/focus" render={(props) => {
                return (<Focus 
                  auth={this.props.auth} 
                  planzu={this.state.planzu} 
                  loadPlanzu={this.props.loadPlanzu}
                  readRemotePlanzu={this.props.readRemotePlanzu}
                  updateRemotePlanzu={this.props.updateRemotePlanzu}
                  addWorkset={this.props.addWorkset}
                  updateWorkset={this.props.updateWorkset}
                  onWorksetChange={this.onWorksetChange}
                  deleteWorkset={this.props.deleteWorkset} 
                  addContext={this.props.addContext}
                  updateContext={this.props.updateContext}
                  deleteContext={this.props.deleteContext}
                  reorderContexts={this.props.reorderContexts}              
                  {...props} 
                />) 
              }}
            />
            <Route 
              exact path="/goals" render={(props) => {
                return (<Goals 
                  auth={this.props.auth} 
                  planzu={this.state.planzu} 
                  setProfile={this.setProfile} 
                  loadPlanzu={this.props.loadPlanzu}
                  readRemotePlanzu={this.props.readRemotePlanzu}
                  updateRemotePlanzu={this.props.updateRemotePlanzu}
                  addWorkset={this.props.addWorkset}
                  updateWorkset={this.props.updateWorkset}
                  onWorksetChange={this.onWorksetChange}
                  deleteWorkset={this.props.deleteWorkset} 
                  addContext={this.props.addContext}
                  updateContext={this.props.updateContext}
                  deleteContext={this.props.deleteContext}
                  reorderContexts={this.props.reorderContexts}              
                  {...props} 
                />) 
              }}
            />
            */}
            <Route path="/blog"  component={Blog} />
            <Route path="/help"  component={Help} />
            <Route path="/about"  component={About} />
            <Route path="/error" component={ErrorPage} />
            {/*
            <Route 
              path="/profile" render={(props) => {
                return this.props.auth.isAuthenticated() ? (
                  <Profile auth={this.props.auth} principal={this.state.principal} {...props} />
                ) : (
                  <Splash auth={this.props.auth} {...props} />
                )              
              }} 
            />
            */}
            <Route 
              path="/callback" render={(props) => {
                this.handleAuthentication(props);
              }}
            />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
