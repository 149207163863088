
const model = {};

model.Workset = class Workset{
  constructor(){
    this.work_description = '';
    this.context = ''; 
    this.notes= '';
    this.is_done = false;
    this.tags=[];
    this.workset_id=null;
    this.calendarEvent = new model.CalendarEvent();
  }
}

model.Context = class Context{
  constructor(defaultColor){
    defaultColor = defaultColor ? defaultColor : '#0A838E';
    this.context_id=null;
    this.name = '';
    this.color = defaultColor;
    this.location = new model.Location();
  }
}

model.Location = class Location{
  constructor(){
    this.name = '';
    this.type='';
    this.description = '';
    this.latitude ='';
    this.longitude = '';
  }
}
model.CalendarEvent = class CalendarEvent{
  constructor(){
    this.title = '';
    this.timestamp_start = '';
    this.timestamp_end = '';
    this.workUnitId = null;
    this.isAllDay = false;
    this.recurrenceRule = null;
    this.description = '';    
  }
}
export default model;