import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import {Row, Input, Button } from 'react-materialize';
import bugSnagHelper from '../../utils/bugSnagHelper'
import ContextItem from './contextItem';
import model from '../../models/models';

const Contexts = props => {
  console.log('in Contexts props is ', props);
  const [new_context, setNew_context] = useState('');

  function onContextChange(event){
    setNew_context(event.target.value);
  }
  function addContext(e) {
    console.log('in context.addContext props.access_token is ', props.access_token);
    e.stopPropagation();
    e.preventDefault();
    //console.log('at addContext');
    ReactGA.event({
      category: 'User',
      action: 'Added a context'
    });
    if (new_context !== "" ) {

      let newContext = new model.Context();
      newContext.name = new_context;
      //when logged in also pass access_token
     props.addContext(newContext);
    }
  }

  return(
    <div className="contexts">
      <div className="contexts-frame">
        <form className="form-planzu" onSubmit={addContext}>
          <Row>
            <Input className="planzu-input" s={12} value={new_context} onChange={onContextChange} placeholder="new context" />
          </Row>
          <Button className="btn-planzu white teal-text text-darken-2" type="submit">add</Button>
        </form>
        {

          (props.planzu.contexts && props.planzu.contexts.length > 0) ?
            <div className="context-container m-t-30">
              {  
                props.planzu.contexts.map(context => {
                  return (
                    <Row key={context.context_id} className="item-container">
                      <ContextItem 
                        context={context} 
                        deleteContext={props.deleteContext}
                      />
                    </Row>
                  )
                })
              }
            </div> : null
        }
      </div>
    </div>
  )
  
}
export default Contexts;