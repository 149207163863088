  const buildTree =  (parentWorkset, worksets) => {
    //console.log('in buildTree parentWorkset is ', parentWorkset);
    //console.log('in buildTree worksets is ', worksets);
    const treeData = buildBranch(parentWorkset, worksets, 0);
    return treeData;
  }
  const buildBranch = (parentWorkset, worksets, buildTreeLevel) =>{
    //console.log('in buildBranch parentWorkset is ', parentWorkset);
    //console.log('in buildBranch worksets is ', worksets);
    //console.log('in buildBranch buildTreeLevel is ', buildTreeLevel);
    let treeData = [];
    const dependents = worksets.filter(workset => {
      return parentWorkset.workset_id  === workset.fulfillment_id;
    });
    dependents.forEach(dependent => {   
      const dependentData = {
        level:buildTreeLevel,
        workset_id: dependent.workset_id, 
        work_description:dependent.work_description, 
        calendarEvent:dependent.calendarEvent,
        context:dependent.context, 
        fulfillment_id:dependent.fulfillment_id,
        notes:dependent.notes
      }
      dependentData.dependents = buildBranch(dependentData, worksets, buildTreeLevel+1)

      treeData.push(dependentData);
    });

    return  treeData;
  }

  export  default buildTree;