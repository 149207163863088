import React, {useState, useEffect} from 'react';
import {Input, Button, Modal } from 'react-materialize';
import PlanzuDal from '../../data/planzuDal';

const circle = `\u25cf`;//: `\u2022`;

const Help = props => {
  console.log('in Help props are ', props);
  const[email_value, setEmail_value] = useState('')
  const[modal_message, setModal_message] = useState('');

  const[showCommentModal, setShowCommentModal] = useState(false);
  const[comment_value, setComment_value] = useState('');

  function onEmailChange(event){
    console.log('in splash.onEmailChange');
    setEmail_value(event.target.value);
  }
  function onCommentChange(event){
    console.log('in splash.onCommentChange');
    setComment_value(event.target.value);  
  }
  function sendComment(){
    console.log('in sendComment');
    const planzuDal = new PlanzuDal();
    if(comment_value && comment_value.length > 2){
      planzuDal.sendComment(comment_value, props.headline);
      const modalMessage = `Thank you, your comment was sent.`;
      openCommentModal(modalMessage);
    }
  }
  function openCommentModal(modalMessage){
    setModal_message(modalMessage);
    setShowCommentModal(true)
  }
  function closeModal(e){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
      setShowCommentModal(false)
      setShowCommentModal(false)
      //console.log('closed modal');
  }
  return (  
    <div className="help">
      <div className="help-frame">
        <h1>{`Help`}</h1>
        <div className="comments">  
          <div className="comments-frame">   
            <div className="headline">
              Comments or questions?
            </div>       
            <div className="comments-body"> 
              <Input 
                className="planzu-input"
                s={12} 
                type="textarea" 
                value={email_value}
                onChange={onEmailChange} 
              />
              <Input 
                className="planzu-input"
                s={12} 
                type="textarea" 
                value={comment_value}
                onChange={onCommentChange} 
              />
              <Button  onClick={()=>{sendComment()}} className="beta-signup-button" >
                Send
              </Button>
            </div>
          </div>
        </div>
 
      </div>
      {
        (showCommentModal) ?
          <Modal id="modal1" className="splash-modal comment-modal" >
            <div className="close right"><a tabIndex={-42} onClick={closeModal}>X</a></div>
            <div className="modal-content">
              <div className="thank-you">
                <div className="modal-headline">Thank you!</div>
                <div className="para">{modal_message}</div>
              </div>
            </div>
          </Modal>:null
      }
    </div>
  )
}
export default Help;

