const createColorObj = (contexts) => {
  let colors={}
  if(contexts && contexts.length > 0 ) {
    colors =  contexts.reduce((obj, item) => {
      obj[item['name']] = item['color']
      return obj
    }, {});

  }
  return colors;
}
export default createColorObj;