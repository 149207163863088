import { connect } from 'react-redux'; 
import { bindActionCreators } from 'redux'; 
import { 
  initPlanzu,
  loadPlanzu,
  readRemotePlanzu,
  updateLocalPlanzu, 
  addWorkset, 
  updateWorkset, 
  deleteWorkset, 
  loadContexts,
  addContext,
  updateContext,
  deleteContext,
  reorderContexts 
} from '../store/planzuActions'; 
import App from '../app'; 
 
const mapStateToProps = state => {
  return  {planzu: state.planzu};
}; 
 
const mapDispatchToProps = dispatch => {
  return  bindActionCreators({ 
    initPlanzu,
    loadPlanzu,
    readRemotePlanzu,
    updateLocalPlanzu,
    addWorkset,
    updateWorkset,
    deleteWorkset,
    loadContexts,
    addContext,
    updateContext,
    deleteContext,
    reorderContexts
  }, dispatch); 
}

export default connect(mapStateToProps, mapDispatchToProps)(App); 