import React from 'react';


const Profile = (props) => (
  <div className="container">
    <div className="profile-area">
      <div>
        <h2>Profile</h2>
        <div className="fs-24"> Name: {props.principal.name} </div>
        <div className="fs-24">ID: {props.principal.user_name}</div>
      </div>
    </div>
  </div>
);

export default Profile