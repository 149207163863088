import React, {useState, useEffect} from 'react';
import Tree from '../tree/tree'
//import bugSnagHelper from '../../utils/bugSnagHelper'
import buildTree from '../../utils/buildTree'

const circle = `\u25cf`;//: `\u2022`;
const Goals = props => {
  console.log('in Goals props are ', props);
  let i = 0;
  //const [goals, setGoals] = useState([]);
  let goals = [];
  if(props.planzu && props.planzu.worksets && props.planzu.worksets && i <= 1){
    i = i + 1;
    console.log(' i is ', i);
    let goals = [];
    props.planzu.worksets.map(workset =>{
      if(!workset.fulfillment_id){
        const tree = buildTree(workset, props.planzu.worksets, 0);
        if(tree && tree.length > 0){
          workset.fulfillmentTree = tree;
          goals.push(workset);
        }
      }
    });
  }
  console.log(' in Goals goals is ', goals);

  return (  
    <div className="goals">
      <div className="goals-frame">
        <h1>{`Goals`}</h1>
        {
          (goals && goals.length > 0) ? goals.map(goal=>(
            <div key={goal.workset_id} className="goals-container">
              <ul>
                <li key={goal.workset_id}>
                  <span className="m-l-10 m-r-10">{circle}</span><a className="fs-24"  href={'/workset/' + goal.workset_id} >{goal.work_description}</a>
                </li>              
                {
                  goal.fulfillmentTree.map( branch => (
                    <Tree 
                      key={branch.workset_id} 
                      workset_id={goal.workset_id}
                      branch={branch} 
                      workset={goal}
                      worksets={this.props.planzu.worksets}
                    />  
                  ))
                }
              </ul>
            </div> 
          )) : null
        } 
      </div>
    </div>
  )
}
export default Goals;

