import React, {useState, useEffect} from 'react';
import ArticlePreview from './articlePreview';
import planzuServices from '../../services/planzuServices';


const Blog = props => {

  const [posts, setPosts] = useState(null);
  useEffect(() =>{
    const blogPromise = planzuServices.getBlog();
  
    blogPromise.then(response => {
      const datedPosts =   response.data.posts.map(post =>{
        const dateNum = new Date(post.date)
        post.dateNum = dateNum;
        return post;
      });
      const sortedPosts =   datedPosts.sort(function(a,b){
        return (a.dateNum < b.dateNum) ? -1 : (a.dateNum > b.dateNum) ? 1 : 0;
      });
      setPosts( sortedPosts );
    })
    .catch(error => {
      console.log('error in get blog error is ',error)
    });
  },[]);

  return (
    <div className="blog">
      { posts ? posts.map((post, index) => <ArticlePreview key={index} post={post} />):null}
      <div className="bottom" />
    </div>
  ); 
}
export default Blog;