import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import bugSnagHelper from '../../utils/bugSnagHelper'


const Focus = props =>{

  const[worksetsGroupedByTag, setWorksetsGroupedByTag] = useState([]);

  function setTags(){
    let tags = [];   
    tags =  props.planzu.worksets.map(workset=>{
      return workset.tags.map(tag=> {return tag.text})
    });
    tags = [].concat.apply([], tags);
    tags = [...new Set(tags)]
    const worksetsGroupedByTag = tags.map(tag =>{ 
      const worksetsWithTag = props.planzu.worksets.filter( workset =>{ 
        const worksetTags = workset.tags.map(tag => tag.text)
        return worksetTags.includes(tag) 
      }); 
      let group = {tag: tag, worksets:worksetsWithTag};
      return group;
      
    });
    console.log('in focus.setTags worksetsGroupedByTag is ', worksetsGroupedByTag);
    setWorksetsGroupedByTag(worksetsGroupedByTag);
  }

  return(
    <div className="focus">
      <div className="focusGroups-frame">
        <h4>Areas of Focus</h4>
        {
          worksetsGroupedByTag ? 
          worksetsGroupedByTag.map( group =>{
            return( 
              <div key={group.tag} className="group">
                <div className="group-title">
                  {`${group.tag}`} <button className="btn-small">Add Item</button>
                </div>
                <div className="workset-container">
                  {
                    group.worksets.map( workset => {
                      return(
                        <div key={workset.workset_id}>
                          <a href={`/workset/${workset.workset_id}`}>{workset.work_description}</a>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          }):null
        }
      </div>
    </div>
  )
  
}
export default Focus;