import React, {useState, useEffect} from 'react';
import renderHTML from 'react-render-html';
import {Link } from 'react-materialize';


const ArticlePreview = props =>{
  return (
    <div className="article">
      {
        props.post ? (
          <div>
            { 
              props.post.featured_image ?
              (<div className="image-container">
                <img alt="featured" src={props.post.featured_image} />
              </div> ): null
            }
            <div className="title">{props.post.title}</div>
            <div className="content">{renderHTML(props.post.content)}</div>
            <hr />
          </div>
        ):null
      }
  
    </div>
  );
}
export default ArticlePreview;