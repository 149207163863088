/* eslint-env browser */

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import createStore from './store/createStore';
import PlanzuContainer from './containers/planzuContainer'
import Auth from './auth/auth';
import reducer from './store/reducer';

ReactGA.initialize('UA-123016169-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const store = createStore(reducer);

fetch("/api/v1/variables")
.then(response => response.json())
.then(variables =>{
  const auth = new Auth(variables); 
  ReactDOM.render( 
    <Provider store={store}>
      <PlanzuContainer auth={auth} />
    </Provider>, 
    document.getElementById('root')
  );
}).catch(error=>{
  console.log('cannot access variables', error);
});

