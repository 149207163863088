import React, {useState, useEffect} from 'react';

import NewWorksetForm from '../newWorkset/newWorkset';
import bugSnagHelper from '../../utils/bugSnagHelper';
import ContextGroups from '../contextGroups/contextGroups'

const  Home = props => {
  const [currentContext, setCurrentContext]  = useState('@inbox');
  const contextGroups = applyContextGroupProperties(props.planzu);

  function onUpdateCurrentContext(context){
    console.log('in onUpdateCurrentContext', context);
    setCurrentContext(context);
    //refresh();
  }
  function onWorksetChange(){
    //console.log('in planzu.onWorksetChange planzu is ', planzu);
    refresh();
  }
  function onContextChange(){
    //console.log('in planzu.onContextChange planzu is ');
    setTimeout(()=>{refresh()}, 100);
  }
  function refresh(){
    //console.log('in home.refresh');
    try {
      props.loadPlanzu();
      if(props.auth.isAuthenticated()){
        props.readRemotePlanzu(props.auth);
      }
    } catch(error){
      bugSnagHelper(error);
    }
  }
  function applyContextGroupProperties(localPlanzu){
    let tempContextGroups = [];
    if(localPlanzu && 
       localPlanzu.worksets && 
       localPlanzu.contexts &&
       localPlanzu.contexts.length > 0 &&
       localPlanzu.contextGroups &&
       localPlanzu.contextGroups.length > 0
    ){
      tempContextGroups=localPlanzu.contextGroups.map(contextGroup =>{
        const context=localPlanzu.contexts.find(contextItem =>{
          return contextItem.name === contextGroup.name;
        });
        contextGroup.color=context.color;
        contextGroup.key=context.key;
        return contextGroup;
      });
    }
    return tempContextGroups;
  }

  return(
    (props.planzu && props.planzu.worksets) ? 
      <div className="home">
        <div className="new-workset-container">
          <NewWorksetForm 
            className 
            auth={props.auth}
            suggestions={props.planzu.suggestions || {}}
            currentContext={currentContext}
            placeholder={`What do you want to do?`}
            addWorkset={props.addWorkset}
            addContext={props.addContext}
            onWorksetChange={onWorksetChange}
            showTimestamp={false}
          />
        </div>
        <div className='contextGroups-container'>
          <ContextGroups 
            //auth={props.auth} 
            planzu={props.planzu}
            contextGroups={contextGroups}
            onUpdateCurrentContext={onUpdateCurrentContext}
            loaPlanzu={props.loadPlanzu}
            addWorkset={props.addWorkset}
            updateWorkset={props.updateWorkset}
            onWorksetChange={onWorksetChange}
            deleteWorkset={props.deleteWorkset} 
            addContext={props.addContext}
            updateContext={props.updateContext}
            deleteContext={props.deleteContext}
            reorderContexts={props.reorderContexts}
            onContextChange={onContextChange}
          />
        </div>
      </div>:null
  );
}
export default Home
