import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import { Button } from 'react-materialize';
import { ReactAgenda,  Modal } from 'react-agenda';
import history from  '../history';
import NewWorksetForm from '../newWorkset/newWorkset';
import bugSnagHelper from '../../utils/bugSnagHelper'
import createColorsObj from '../../utils/createColorsObj';

const Calendar = props => {
  console.log('entering Calendar props is ', props);
  const cellHeight = 10;
  const locale = "us";
  const rowsPerHour = 2;
  const numberOfDays = 4
  const [colors, setColors] = useState( {'color-1':"rgba(102, 195, 131 , 1)"});
  const [firstWorkset, setFirstWorkset] = useState('');
  const [calendarEvents, setCalendarEvents] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [timestamp_start, setTimestamp_start] = useState('');
  const [timestamp_end, setTimestamp_end] = useState('');

  if(props && props.planzu && props.planzu.worksets && props.planzu.worksets.length > 0 && props.planzu.worksets[0].work_description !== firstWorkset){
    setFirstWorkset(props.planzu.worksets[0].work_description);
    const tempColors = createColorsObj(props.planzu.contexts);
    setColors(tempColors);
    setCalendar();
  }
  
  function addNewEvent(e){ 
    console.log('in add new event', e);
    openModal();
  }
  
  function setCalendar(){
    console.log('in setCalendar', props.planzu.worksets);
    if(props.planzu && props.planzu.worksets){
      const calendarEvents = props.planzu.worksets.filter(workset =>{ 
        if(moment(workset.calendarEvent.timestamp_start).isValid()  && moment(workset.calendarEvent.timestamp_end).isValid()){
          let calendarEvent ={}
          calendarEvent._id = workset.workset_id;
          calendarEvent.startDateTime = moment(workset.calendarEvent.timestamp_start).toDate();
          calendarEvent.endDateTime = moment(workset.calendarEvent.timestamp_end).toDate();
          calendarEvent.name = workset.work_description;
          calendarEvent.classes =  workset.context;
          return calendarEvent;
      } else if(moment(workset.calendarEvent.timestamp_start).isValid() && !moment(workset.calendarEvent.timestamp_end).isValid()){
          let calendarEvent ={}
          calendarEvent._id = workset.workset_id;
          calendarEvent.startDateTime = moment(workset.calendarEvent.timestamp_start).toDate();
          calendarEvent.endDateTime = moment(workset.calendarEvent.timestamp_start).add(1, 'hours').toDate();
          calendarEvent.name = workset.work_description;
          calendarEvent.classes =  workset.context;
          return calendarEvent;
        }
      });
      setCalendarEvents(calendarEvents);
    }
  }
  function openModal(){
    setShowModal(true)
  }
  function closeModal(e){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
      setShowModal(false)
      console.log('closed modal');
  }

  function handleItemEdit(item){
    console.log('at calendar.handleItemEdit', item);
    history.push('/workset/' + item._id);
    history.go()
  }


  function handleRangeSelection(selected){
    console.log('at calendar.handleRangeSelection', selected);
    setTimestamp_start(moment(selected[0]).format('MM/DD/YYYY hh:mm a'));
    setTimestamp_end(moment(selected[1]).format('MM/DD/YYYY hh:mm a'));
  }
  function handleCellSelection(item){
    console.log('handleCellSelection',item);
    setTimestamp_start(moment(item).format('MM/DD/YYYY hh:mm a'));
    setTimestamp_end(moment(item).add(1,'hours').format('MM/DD/YYYY hh:mm a'));
  }
  function refresh(){
    try {
      props.loadPlanzu();
    } catch(error){
      bugSnagHelper(error);
    }
  }

  return(
    <div className="calendar">
      <div className="calendar-frame">
        <Button className="btn-planzu white teal-text text-darken-2"  onClick={addNewEvent}>
          Add New
        </Button>
        {
          console.log('calendarEvents is ', calendarEvents)
          /*
        calendarEvents ? 
          <ReactAgenda
            minDate={moment().toDate()}
            maxDate={moment().add(31,'days').toDate()}
            startDate={moment().toDate()}
            cellHeight={cellHeight}
            locale={locale}
            items={calendarEvents}
            numberOfDays={numberOfDays}
            rowsPerHour={rowsPerHour}
            itemColors={colors}
            autoScale 
            fixedHeader 
            onItemEdit={handleItemEdit}
            onCellSelect={handleCellSelection}
            onRangeSelection={handleRangeSelection}
          /> : null 
          */
        }
        {
          showModal ?
            <Modal clickOutside={()=>setShowModal(false)} >
              <div className="modal-content">
                <NewWorksetForm 
                  className 
                  suggestions={props.suggestions || {}}
                  currentContext={'@inbox'}
                  placeholder={`What do you want to do?`}
                  addWorkset={props.addWorkset}
                  addContext={props.addContext}
                  timestampStart={timestamp_start}
                  timestampEnd={timestamp_end}
                  showTimestamp
                />
              </div>
            </Modal>:null
        }
      </div>
    </div>
  )

}
export default Calendar;