import { 
  INIT_PLANZU,
  LOAD_PLANZU, 
  READ_REMOTE_PLANZU,
  ADD_WORKSET, 
  UPDATE_WORKSET, 
  DELETE_WORKSET,
  READ_CONTEXT,  
  ADD_CONTEXT, 
  UPDATE_CONTEXT,
  DELETE_CONTEXT,
  UPDATE_CONTEXTS
} from './actionTypes';

const reducer  = (state = {}, action = {}) => { 
  switch (action.type) { 
    case INIT_PLANZU:
      return action.payload || {};
    case LOAD_PLANZU: 
      return action.payload || {}; 
    case READ_REMOTE_PLANZU:
      return action.payload || {};
    case ADD_WORKSET: 
      return action.payload || {};
    case UPDATE_WORKSET:
      return action.payload || {};
    case DELETE_WORKSET: 
      return action.payload || {};
    case READ_CONTEXT:
      return action.payload || {};
    case ADD_CONTEXT: 
      return action.payload || {}; 
    case UPDATE_CONTEXT:
      return action.payload || {}; 
    case DELETE_CONTEXT:
      return action.payload || {};
    case UPDATE_CONTEXTS:
      return action.payload || {};
    default: 
      return state; 
  } 
};
export default reducer;