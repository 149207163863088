import React, {useState} from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import {Input, Autocomplete, Modal } from 'react-materialize';
import Datetime from 'react-datetime';
import moment from 'moment';
import NewWorksetForm from '../newWorkset/newWorkset';
import Tree from '../tree/tree'
import model from '../../models/models';
import buildTree from '../../utils/buildTree'


const WorksetDetails = props => {
  //console.log('in WorksetDetails props is ', props);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [currentWorkset, setCurrentWorkset] = useState({});
  const [workset_id, setWorkset_id] = useState('');
  const [workset_value, setWorkset_value] = useState('');
  const [context_value, setContext_value] = useState('');
  const [notes_value, setNotes_value] = useState('');
  const [timestamp_start, setTimestamp_start] = useState('');
  const [timestamp_end, setTimestamp_end] = useState('');
  const [tag_suggestions, setTag_suggestions] = useState(''); 
  const [tags, setTags] = useState('');
  const [fulfillment_id, setFullfillment_id] = useState('');
  const [fulfillmentTree, setFulfillmentTree] = useState([]);
  const [showModal, setShowModal] = useState(false);


  if(props.planzu && props.planzu.worksets && props.planzu.worksets.length > 0){
    console.log('in worksetDetails props.planzu is ', props.planzu);
    const foundWorkset = props.planzu.worksets.find(worksetItem =>{
      return worksetItem.workset_id === props.match.params.id;
    });
    if(workset_value !== foundWorkset.work_description){
      setCurrentWorkset(foundWorkset);
      setWorkset_id(foundWorkset.workset_id);
      setWorkset_value(foundWorkset.work_description);
      setContext_value(foundWorkset.context);
      setNotes_value(foundWorkset.notes);
      setTimestamp_start(foundWorkset.calendarEvent.timestamp_start);
      setTimestamp_end(foundWorkset.calendarEvent.timestamp_end);
      setFullfillment_id(foundWorkset.fulfillment_id);
      setTags(foundWorkset.tags);
      const tempFulfillmentTree = buildTree(foundWorkset, props.planzu.worksets, 0);
      console.log('in worksetDetails timestamp_start is ',timestamp_start)
      console.log('in worksetDetails timestamp_end is ', timestamp_end)
      console.log('in worksetDetails tempFulfillmentTree is ', tempFulfillmentTree)
      setFulfillmentTree(tempFulfillmentTree);
    }
  }
  function onWorkDescriptionChange(event){
    setWorkset_value(event.target.value);
  }
  function onContextChange(event) {
    if(event.target.value){
      setContext_value(event.target.value);
    }
  }
  function onContextFocus(){
    if(context_value === '@inbox'){
      setContext_value('@');
    }
  }
  function onDateStartedChange(event){
    //console.log('in worksetDetails.onDateStartedChange event is ', event);
    //console.log('in worksetDetails.onDateStartedChange typeof event is ', typeof event);
    if(typeof event === 'string'){
      if(moment(event).isValid()){
        //console.log('string update is valid string');
        setTimestamp_start( event);
      } else {
        console.log('string update is invalid');
      }
    } else {
      setTimestamp_start( moment(event).format('MM/DD/YYYY hh:mm a'));
    }
  }
  function onDateEndedChange(event){
    if(typeof event === 'string'){
      if(moment(event).isValid()){
        //console.log('string update is valid string');
        setTimestamp_end(event);
      } else {
        console.log('string update is invalid');
      }
    } else {
      setTimestamp_end(  moment(event).format('MM/DD/YYYY hh:mm a'));
    }
  }
  function onNotesChange(event){
    setNotes_value(event.target.value);
  }
  function onAutocompleteFocus(){
    if(context_value === '@inbox') {
     setContext_value('@');
    }
  }
  function onWorksetChange(){
    console.log('in worksetDetails.onWorksetChange');
  }
  function setAutocomplete(context) {
    if(context){
      setContext_value(context);
    }
  }
  function updateWorkset(e){
    e.stopPropagation();
    e.preventDefault();
    if(workset_value && workset_value.length > 0 &&
      context_value && context_value.length > 0) {

      let newContext = new model.Context();
      newContext.name =context_value;

      let updatedWorkset = new model.Workset();
      updatedWorkset.workset_id = workset_id;
      updatedWorkset.work_description = workset_value;
      updatedWorkset.context = context_value;
      updatedWorkset.calendarEvent.timestamp_start = timestamp_start;
      updatedWorkset.calendarEvent.timestamp_end = timestamp_end;
      updatedWorkset.tags = tags;
      updatedWorkset.notes = notes_value;
      updatedWorkset.fulfillment_id = fulfillment_id;
      //console.log('workset_details.updateWorkset updatedWorkset is ', updatedWorkset);
      props.updateWorkset(updatedWorkset);
      props.addContext(newContext);
    }
  } 
  function handleDelete(i) {
    const filteredTags = tags.filter((tag, index) => index !== i);
    setTags(filteredTags);
  }

  function handleAddition(tag) {
    setTags([...tags, tag]);
  }
  function handleDrag(tag, currPos, newPos) {
    const tags = [...tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  }
  function openModal(){
    setShowModal(true)
  }
  function closeModal(e){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
      setShowModal(false)
      //console.log('closed modal');
  }
  return (  
    <div className="worksetDetails">
      {
        showModal?
          <Modal id="modal1" className="workset-details-modal" >
            <div className="close right"><a tabIndex={-42} onClick={closeModal}>X</a></div>
            <div className="modal-content">
              <NewWorksetForm 
                className 
                suggestions={props.suggestions || {}}
                currentContext={'@inbox'}
                placeholder={`What do you want to do?`}
                addWorkset={props.addWorkset}
                addContext={props.addContext}
                onWorksetChange={onWorksetChange}
                timestampStart={timestamp_start}
                timestampEnd={timestamp_end}
                showTimestamp={false}
                fulfillment_id={workset_id}
              />
            </div>
          </Modal>:null
      }

      <div className="content">
        <div className="form-planzu">
          <div>
            <div className="planzu-field-label">Description</div>
            <Input 
              className="planzu-input"  
              value={workset_value} 
              onChange={onWorkDescriptionChange} 
            />         
          </div>
          <div>
            <div className="planzu-field-label">Context</div>
            <Autocomplete
              label="Context"
              value={context_value}
              s={12}
              className="autocomplete"
              data={props.planzu.suggestions}
              onChange={onContextChange}
              onAutocomplete={setAutocomplete}
              onFocus={onAutocompleteFocus}
            />
          </div>
          <div className="date-row">
            <div className="date-cell">
              <div className="planzu-field-label">Date Started</div>
              <div className="date-container planzu-input">
                <Datetime 
                  value={timestamp_start} 
                  onChange={onDateStartedChange} 
                />
              </div>
            </div>

            <div className="date-cell">
              <div className="planzu-field-label">Date Ended</div>
              <div className="date-container planzu-input">
                <Datetime 
                  value={timestamp_end} 
                  onChange={onDateEndedChange} 
                />
              </div>
            </div>
          </div>
          <div>
            <div className="planzu-field-label tag-label">Tags (Areas of Focus)</div>
            <ReactTags 
              className="planzu-input"
              tags={props.tags}
              suggestions={props.tag_suggestions}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              delimiters={delimiters} 
            />
          </div>
          <div>
            <div className="planzu-field-label">Notes</div>
            <Input 
              className="planzu-input" 
              //s={12} 
              type="textarea" 
              value={notes_value}
              onChange={onNotesChange} 
            />
          </div>
          <button className="btn-planzu"  onClick={updateWorkset}>
            Update
          </button>
          <div className="subtastk-link-container">
            <button className="btn-planzu-link" onClick={openModal}>Add Subtask</button>
          </div>
          <div>
            {
              (fulfillmentTree && fulfillmentTree.length > 0) ?
                <div className="teal lighten-5 p-a-10">
                  <h5>Subtasks</h5>
                  <ul>
                    {
                      fulfillmentTree.map( branch => (
                        <Tree 
                          key={branch.workset_id} 
                          workset_id={branch.workset_id}
                          branch={branch} 
                          workset={currentWorkset}
                          worksets={props.planzu.worksets}              
                        />
                      ))
                    }
                  </ul>
                </div> 
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default WorksetDetails;