import React, {useState, useEffect} from 'react';
import TextLoop from 'react-text-loop';
import ReactGA from 'react-ga';
import {Input, Button, Modal } from 'react-materialize';
import moment from 'moment';
import PlanzuDal from '../../data/planzuDal';

const Splash =(props) => {

  const[email_value, setEmail_value] = useState('')
  const[comment_value, setComment_value] = useState('');
  const[modal_message, setModal_message] = useState('');
  const[placeholder, setPlaceholder] = useState('Email Address');
  const[showSignupModal, setShowSignupModal] = useState(false);
  const[showCommentModal, setShowCommentModal] = useState(false);

  function onEmailChange(event){
    console.log('in splash.onEmailChange');
    setEmail_value(event.target.value);
  }
  function onCommentChange(event){
    console.log('in splash.onCommentChange');
    setComment_value(event.target.value);  
  }
  function betaSignup(){
    console.log('in beta signup');
    const planzuDal = new PlanzuDal();
    if(email_value && email_value.length > 2){
      planzuDal.betaSignup(email_value, props.headline);
      const modalMessage = `You signed up to beta test the Planzu iOS app. You'll receive an email with instructions on how to get the beta version of Planzu shortly.`;
      openSignupModal(modalMessage);
    }
  }
  function sendComment(){
    console.log('in sendComment');
    ReactGA.event({
      category: 'marketing',
      action: 'Send Comment'
    });
    const planzuDal = new PlanzuDal();
    if(comment_value && comment_value.length > 2){
      planzuDal.sendComment(comment_value, props.headline);
      const modalMessage = `Thank you, your comment was sent.`;
      openCommentModal(modalMessage);
    }
  }
  function openSignupModal(modalMessage){
    setModal_message(modalMessage);
    setShowSignupModal(true)
  }
  function openCommentModal(modalMessage){
    setModal_message(modalMessage);
    setShowCommentModal(true)
  }
  function closeModal(e){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
      setShowCommentModal(false)
      setShowCommentModal(false)
      //console.log('closed modal');
  }
  function login() {
    //console.log('in home.login');
    props.auth.login();  
  }
  function redirectToApple(){
    ReactGA.event({
      category: 'Marketing',
      action: 'Send To Apple'
    });
    window.location.href = 'https://apps.apple.com/app/id1465317427'; 
     return null;
  }
  function redirectToGoogle(){
    ReactGA.event({
      category: 'Marketing',
      action: 'Send To Google'
    });
    window.location.href = 'https://play.google.com/store/apps/details?id=com.planzu'; 
     return null;
  }
  function jumpTo(anchor){
      window.location.href = "#"+anchor;
  }
  return(
  
    <div className="splash">
      
      <div className="splash-header">
        <div className="splash-header-frame">
          <div className="lead-content">
            <div className="headline">
              {props.headline}
            </div>
            <div className="summary">
              {`Planzu is a new mobile app. It's a ToDo-list-manager focused on getting things done. It's your next personal organizer. `}
            </div>
            <div className="summary">
              <TextLoop 
                className="text-loop"
                interval={3000} 
                delay={1000} 
                adjustingSpeed={1} 
                springConfig={{stiffness: 140, damping: 30 }}
              >
                <span>It&apos;s fast and simple.</span>
                <span>It implements GTD.</span>
                <span>It uses contexts.</span>
                <span>{`It let's you master your workflow.`}</span>
                {/*<span>Enables your intuitive action.</span>*/}
                <span>It keeps you in the zone.</span>
                {/*<span>Maintains your commitments.</span>*/}
                <span>It keeps your head clear.</span>
                <span>It helps you trust your life choices.</span>
                <span>It helps you think effectively.</span>
                <span>Text your future self.</span>
                <span>It&apos;s an iOS app.</span>
                <span>It&apos;s an Android app.</span>

              </TextLoop>
            </div>
            <div className="beta-signup">
              <div className="imageContainer">
                <a className="badge" tabIndex={-42} onClick={()=>{redirectToApple()}}>
                  <img className="ios-badge" alt="iOS app store link" src="/img/app-store-logo.png" />
                </a>
                <a className="badge" tabIndex={-42} onClick={()=>{redirectToGoogle()}}>
                  <img className="android-badge" alt="android app store link" src="/img/google-play-logo.png"  />
                </a>
              </div>  
            </div>
            <div className="learn-more-link">
              <a tabIndex={-42} onClick={()=>{jumpTo('learn-more-panel')}} >Learn More</a>
            </div>
          </div>
          <div className="graphic-frame">
            <a href='/home'>
              <img alt="planzu graphic" src="/img/gettingStarted_300_600_120.gif" />
            </a>
          </div>
        </div>
      </div>
      <div id="learn-more-panel" className="learn-more">
        <div className="learn-more-frame">
          <div className="what">

            <div className="what-frame">
              <div className="headline">
                What is Planzu?
              </div>
              <div className="what-body">
                <div className="para">
                  Planzu is a new To Do app for iOS and Android that that lets you list the things you want to remember.
                </div>
              </div>
              <div className="headline">
                How do I use Planzu?
              </div>
              <div className="what-body">
                <div className="para">
                  There are two main fields in planzu.
                </div>
                <div className="para">
                  The first field is for the things you want to remember. Simply jot them down.
                  The second field is for where you&apos;d like to remember them. @home, @store, @work etc.
                  The default is  @inbox.
                </div>
                <div className="para">
                  You can get into the details of each thing and set calendar dates, notes and subtasks.
                </div>
              </div>
            </div>
          </div>
          <div className="comments">  
            <div className="comments-frame">   
              <div className="headline">
                Comments or questions?
              </div>       
              <div className="comments-body"> 
                <Input 
                  className="planzu-input"
                  s={12} 
                  type="textarea" 
                  value={comment_value}
                  onChange={onCommentChange} 
                />
                <Button  onClick={()=>{sendComment()}} className="beta-signup-button" >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-item-promote">
            <div className="logo">
              <div className="brand">Planzu</div>
            </div>
            <div className="tagline"> {props.headline}</div>
            <div className="social">
              <a href="https://www.facebook.com/planzu" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook" /></a>
              <a href="https://pinterest.com/planzu" target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest-square" /></a>
              <a href="https://twitter.com/planzu" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square" /></a>
              <a href="https://instagram.com/planzu" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
              <a href="mailto:info@planzu.com" target="_blank" rel="noopener noreferrer"><i className="far fa-envelope" /></a>
            </div>
            <div className="hashtag">@planzu  #PLANZU</div>
          </div>
          <div className="footer-item-disclose">
            <div className="terms">
              <a href="/terms">
                Terms of use.
              </a>
              <a href="/privacy">
                Privacy policy
              </a>
              <a href="/cookies">
                Cookie policy
              </a>
            </div>
            <div className="copyright">
              <small>
                ©{moment().format('YYYY')} Planzu, Inc. All rights reserved.
              </small>
            </div>
          </div>
        </div>   
      </div>
      {
        (showSignupModal) ?
          <Modal id="modal1" className="splash-modal signup-modal" >
            <div className="close right"><a tabIndex={-42} onClick={closeModal}>X</a></div>
            <div className="modal-content">
              <div className="thank-you">
                <div className="modal-headline">Thank you!</div>
                <div className="para">{modal_message}</div>
              </div>
            </div>
          </Modal>:null
      }
      {
        (showCommentModal) ?
          <Modal id="modal1" className="splash-modal comment-modal" >
            <div className="close right"><a tabIndex={-42} onClick={closeModal}>X</a></div>
            <div className="modal-content">
              <div className="thank-you">
                <div className="modal-headline">Thank you!</div>
                <div className="para">{modal_message}</div>
              </div>
            </div>
          </Modal>:null
      }
    </div>
  )

}

export default Splash