

import React, {useState, useEffect} from 'react';
import {Input, Button, Autocomplete } from 'react-materialize';
import Datetime from 'react-datetime';
import moment from 'moment';
import bugSnagHelper from '../../utils/bugSnagHelper';

import model from '../../models/models';

const NewWorksetForm = props => {

  const [context_value, setContext_value] = useState('@inbox');
  const [workset_value, setWorkset_value] = useState('');
  const [timestamp_start, setTimestamp_start] = useState('');
  const [timestamp_end, setTimestamp_end] = useState('');
  const [showTimestamp, setShowTimestamp] = useState(false);

  if(context_value !== props.currentContext){
    setContext_value(props.currentContext);
  }
  if(props.showTimestamp && props.showTimestamp !== showTimestamp){
    setShowTimestamp(props.showTimestamp);
  }
  
  function onContextChange(event) {
    if(event.target.value){
      setContext_value( event.target.value);
    }
  }
  function onWorksetChange(event){
    setWorkset_value(event.target.value);
  }
  function onContextFocus(){
    if(context_value === '@inbox'){
      setContext_value('@');
    }
  }
  function onDateStartedChange(event){
    //console.log('in worksetDetails.onDateStartedChange event is ', event);
    //console.log('in worksetDetails.onDateStartedChange typeof event is ', typeof event);
    if(typeof event === 'string'){
      if(moment(event).isValid()){
        //console.log('string update is valid string');
        setTimestamp_start(event);
      } else {
        console.log('string update is invalid');
      }
    } else {
      setTimestamp_start(  moment(event).format('MM/DD/YYYY hh:mm a'));
    }
  }
  function onDateEndedChange(event){
    if(typeof event === 'string'){
      if(moment(event).isValid()){
        //console.log('string update is valid string');
        setTimestamp_end( event);
      } else {
        console.log('string update is invalid');
      }
    } else {
      setTimestamp_end( moment(event).format('MM/DD/YYYY hh:mm a'));
    }
  }
  function setAutocomplete(context) {
    if(context){
      setContext_value(context);
    }
  }
  function addWorkset(e){
    //console.log('in newWorkset.addWorkset props.fulfillment_id ', props.fulfillment_id );
    e.stopPropagation();
    e.preventDefault();
    try{
      if(workset_value && workset_value.length > 0 &&
        context_value && context_value.length > 0) {

        let newContext = new model.Context();
        newContext.name =context_value;

        let newWorkset = new model.Workset();
        newWorkset.work_description = workset_value;
        newWorkset.fulfillment_id = props.fulfillment_id || null;
        newWorkset.context = context_value;
        newWorkset.calendarEvent.timestamp_start = timestamp_start || '';
        newWorkset.calendarEvent.timestamp_end = timestamp_end || '';
        if(props.tags && props.tags.length > 0){
          newWorkset.tags = props.tags;
        }
        props.addWorkset(newWorkset);
        props.addContext(newContext);
        setWorkset_value('');
        setTimestamp_start('');
        setTimestamp_end('');

      }
    } catch(error){
      bugSnagHelper(error);
    }
  }
  return(
    <div>
      <form className="form-planzu">
        <Input className="planzu-input" s={12} value={workset_value} onChange={onWorksetChange}  placeholder={props.placeholder} />
        <Autocomplete
          label="Context"
          value={context_value}
          s={12}
          className="autocomplete"
          data={props.suggestions}
          onChange={onContextChange}
          onAutocomplete={setAutocomplete}
          onFocus={onContextFocus}
        />
        {
          showTimestamp ? 
            <div className="date-row">
              <div className="date-cell">
                <div className="planzu-field-label">Date Started</div>
                <div className="date-container planzu-input">
                  <Datetime 
                    value={timestamp_start} 
                    onChange={onDateStartedChange} 
                  />
                </div>
              </div>

              <div className="date-cell">
                <div className="planzu-field-label">Date Ended</div>
                <div className="date-container planzu-input">
                  <Datetime 
                    value={timestamp_end} 
                    onChange={onDateEndedChange} 
                  />
                </div>
              </div>
            </div>:null
        }
        <Button className="btn-planzu white teal-text text-darken-2" type="submit" onClick={addWorkset} >add</Button>
      </form>
    </div>
  )
}
export default NewWorksetForm;