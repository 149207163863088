export const LOAD_PLANZU = 'LOAD_PLANZU';
export const READ_REMOTE_PLANZU = 'READ_REMOTE_PLANZU'; 
export const UPDATE_LOCAL_PLANZU = 'UPDATE_LOCAL_PLANZU'; 
export const TEST_PLANZU = 'TEST_PLANZU';
export const INIT_PLANZU = 'INIT_PLANZU';
export const ADD_WORKSET = 'ADD_WORKSET';
export const READ_WORKSET = 'READ_WORKSET';
export const UPDATE_WORKSET = 'UPDATE_WORKSET';
export const DELETE_WORKSET = 'DELETE_WORKSET';
export const ADD_CONTEXT = 'ADD_CONTEXT'
export const READ_CONTEXT = 'READ_CONTEXT';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const DELETE_CONTEXT = 'DELETE_CONTEXT';
export const REORDER_CONTEXTS = 'REORDER_CONTEXTS';

