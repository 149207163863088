
import React from 'react';

const ContextItem = (props) => { 
  //console.log('in contextItem props is ', props);
  const link = '/context/' + props.context.context_id;
  const swatchStyle = props.context.color ? {background: props.context.color} : {background: '#0A838E'};

  const deleteContext = () => {
    props.deleteContext(props.context.context_id);
  }
  return (
    <div className="context_item" key={props.context.context_id}>
      <div className="color_container">
        <div className="color" style={swatchStyle} />
      </div>
      <div className="link_container">
        <a href={link}>{props.context.name}</a>
      </div>
      <div className="controls_container">
        <a className='delete-button' tabIndex={-42} onClick={()=>{deleteContext()}}>{`\u274C`}</a>
      </div>
    </div>
  )
}

export default ContextItem 