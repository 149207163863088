import React from 'react';
/*
  '/img/calendardeskTint.jpg',p
  '/img/capture.jpg',
  '/img/clarify.jpg',
  '/img/compass.jpg'
*/

const About  = () => (
  <div className="about p-a-30">
    <div>
      <div className="lobster fs-40 m-b-30 m-t-20">Welcome to Planzu</div>
    </div>
    <p className="fw-500 fs-20">Planzu is an app that helps you get organized and get things done.</p>
    <p className="p-l-20">
      {
        `Planzu was built with David Allen's Getting Things Done principles in mind. If you're not familiar with GTD, 
        you can find out more here https://gtdconnect.com/gtdq.php`
      }
    </p>
    <p className="fw-600 fs-20">1 Capture and collect</p>
    <p className="p-l-20">The first step to getting organized is to be able to capture reminders wherever and whenever
      it occurs to you. Planzu 
    </p>
    <p className="fw-600 fs-20">2 Clarify what the thing means</p>
    <div className="p-l-20">
      Planzu helps you flesh out individual ideas if you need.
      <ul className="p-l-20">
        <li>Add notes</li>
        <li>Further things to do.</li>
        <li>Set a calendar item</li>
      </ul>
    </div>         
    <p className="fw-600 fs-20">3 Organize</p>
    <p className="p-l-20">
      Having a long list of everything you need to do is a good start, but ultimately you only need to remember
      certain things in certain places. 
      Planzu can help you organize your thoughts into categories, i.e contexts. 
      Grouping together things you need to remember at home, at work, at the grocery store, at the hardware store gets you organized so you only see what you need to see at any given time.
    </p>         

    <p className="fw-600 fs-20">4 Reflect and review</p>
    <p className="p-l-20">
      Collecting and organizing is great, but every day, things change. New things come up.
      Ideas become obsolete or change due to circumstances. 
    </p>        

    <p className="fw-600 fs-20">5 Engage</p> 
    <p className="p-l-20">
      {"When you aren't capturing, clarifying, organizing or reflecting, you're doing "}
      Planzu is right there on your phone where you have fast and easy access to all your items,
      grouped according to contexts. You see your reminders where you need tp see them.
    </p>
  </div>
)

export default About