import _ from 'lodash';

const setContextGroups = (worksets) => {
  let contextGroups = [];
  if(worksets && worksets.length > 0) {
    const groupedByContextObject = _.groupBy(worksets, 'context');
    contextGroups = Object.keys(groupedByContextObject).map(function(data){
      return [data, groupedByContextObject[data]];
    });
    contextGroups = contextGroups.map(item =>{
      return {name:item[0], worksets:item[1]}
    });
  }
  return contextGroups;
}
export default setContextGroups;