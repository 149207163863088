import React, {useState, useEffect} from 'react';
import Masonry from 'react-masonry-component';
import {Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import luminanceByColor from '../../utils/luminanceByColor'
import history from  '../history';
//import bugSnagHelper from '../../utils/bugSnagHelper'

const ContextGroups = props => {

  const masonryOptions = {
    transitionDuration: 0,
    itemSelector: '.contextGroup-container'
  };
  function onContexHeaderClick(event){
    props.onUpdateCurrentContext(event.target.innerText)
  }
  function onDragStart(e){
    //todo -set style with drop shadow here
    console.log('in onDragStart. e is ', e);
  }
  function onDragUpdate(e){
    //todo -set style with drop shadow here
    console.log('in onDragUpdate. e is ', e);
  }
  function onDragEnd(e){
    //console.log('in onDragEnd e is ', e);
    //TODO - animate contextGroup container downwards by a
    if(e.destination){
      const contextGroupName = e.destination.droppableId;
      const worksetId = e.draggableId;
      updateWorkset(worksetId, contextGroupName);
      //console.log('change worksetId ' + worksetId + ' to contextGroup ' + contextGroupName);
    } else {
      //console.log('nothing to drop on');
    }
  }
  function onWorksetDetails(workset_id){
    //console.log('on workset details', workset_id)
    history.push('/workset/' + workset_id);
    history.go()
  }

  function updateWorkset(updatedWorksetId, contextName){
    //console.log('in updateWorkset updatedWorksetId is ', updatedWorksetId);
    //console.log('in updateWorkset contextName is ', contextName);

    const updatedWorkset = props.planzu.worksets.find( _workset =>{
      return _workset.workset_id ===updatedWorksetId;
    });

    if(updatedWorkset){
      updatedWorkset.context = contextName;
      props.updateWorkset(updatedWorkset);
    }
  }
  function deleteWorkset(workset_id) {
    props.deleteWorkset(workset_id);
  }


  return(
    <div className="contextGroups">
      <div className="screen">
        <DragDropContext 
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
        >
          <div>
            <div>
              <Masonry
                key={1}
                className={'my-gallery-class'}  // default ''
                elementType={'ul'} // default 'div'
                gutter={20}
                percentposition={'true'}
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              >
                {  
                  props.planzu.contextGroups ?
                  props.planzu.contextGroups.map(contextGroup => {
                    const foundContext = props.planzu.contexts.find( _context =>{
                      return _context.name === contextGroup.name;
                    });
                    //if(!foundContext){console.log('foundContext is undefined state.contexts is ', props.planzu.contexts);}
                    const foundColor = (foundContext && foundContext.color) ? foundContext.color : '#0A838E';
                    const luminance = luminanceByColor(foundColor);
                    const fontColor = (luminance > 120) ? '#000000' : '#ffffff';
                    const swatchStyle = {background: foundColor, color:fontColor, borderColor:foundColor};

                    return (
                      <li key={contextGroup.name} className="contextGroup-container">
                        <div className="contextGroup-header" style={swatchStyle}>
                          <a  tabIndex={-42} style={swatchStyle} onClick={onContexHeaderClick}>{contextGroup.name}</a>
                        </div>
                        <div className="contextGroup-body">
                          <Droppable key={contextGroup.name} droppableId={contextGroup.name} type="WORKSETITEM">
                            {(provided, snapshot) => {
                                return (
                                  <div key={contextGroup.name} ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                      contextGroup.worksets.map(workset => (
                                        <Draggable key={workset.workset_id} draggableId={workset.workset_id} index={0}>
                                          {(provided, snapshot) => {
                                            return (
                                              <div key={workset.workset_id} ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}> 
                                                <div className="workset_item" key={workset.workset_id}>
                                                  <div className="workset_link_container">
                                                    <a className="workset_link" tabIndex={-42} onClick={()=>{onWorksetDetails(workset.workset_id)}} > {workset.work_description}</a>
                                                  </div>
                                                  <div className="edit_container">
                                                    <div className="workset_delete"><a  tabIndex={-42} onClick={()=>{deleteWorkset(workset.workset_id)}}>{`\u274c`}</a></div>
                                                    <div className="workset_handle">{`\u281b`}</div>
                                                  </div>
                                                </div>      
                                              </div>)
                                          }}
                                        </Draggable>
                                      ))
                                    }
                                  </div>
                                )
                              }
                            }
                          </Droppable>    
                        </div>
                      </li>
                    )
                  }) : null
                }
              </Masonry>
            </div>
          </div>
        </DragDropContext>
      </div>
      {/*
      <div className="print">
        {  
          props.planzu.contextGroups.map(contextGroup => {
            const foundContext = props.planzu.contexts.find( _context =>{
              return _context.name === contextGroup.name;
            });
            return (
              <div key={contextGroup.name} className="contextGroup-container p-t-20">
                <div className="fw-500">{contextGroup.name}</div>
                <div className="p-a-15">
                  {
                    contextGroup.worksets.map(workset => {
                      return (
                        <div className="p-l-20" key={workset.workset_id}>
                          {workset.work_description}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        } 
      </div>
      */}
    </div>
  )
}
export default ContextGroups;
