import React from 'react';
import {Navbar, NavItem } from 'react-materialize';

const Menu  = (props) => {
  //console.log('in menu props is ', props);
  const handleLoginLogout = () => {
    props.auth.isAuthenticated() ? props.auth.logout() : props.auth.login();
  }
  return(
    <div className="menu">
      {
        <Navbar className="cyan darken-3" brand="Planzu" right>
          <NavItem href="/home">Home</NavItem>
          {/*
          <NavItem href="/contexts">Contexts</NavItem>
          <NavItem href="/calendar">Calendar</NavItem>
          <NavItem href="/focus">Focus</NavItem>
          */}
          <NavItem href="/blog">Blog</NavItem>
          {/*
          <NavItem href="/goals">Goals</NavItem>
          */}
          <NavItem href="/about">About</NavItem>
          {/*
          <NavItem href="/profile">Profile</NavItem>
          <NavItem onClick={() => handleLoginLogout()}>{props.auth.isAuthenticated() ? 'Logout' : 'Login'}</NavItem>
          */}
        </Navbar>  
      }
    </div>
  )
}
export default Menu