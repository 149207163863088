import axios from 'axios';

const planzuServices = {};

planzuServices.getBlog = () =>{
  return new Promise((resolve, reject) =>{
    axios.get("https://public-api.wordpress.com/rest/v1/sites/planzu.wordpress.com/posts?_jsonp=receiveData")
    .then(response =>{
      console.log('blog response', response)
      resolve( response);
    })
    .catch(error=>{
      reject(error);
    })

  });
}

planzuServices.getPlanzu = (access_token, userProfile) => {
  if(access_token && userProfile){
    return new Promise((resolve, reject) => {
      //console.log('in planzuServices.getPlanzu');
      //console.log('access_token;  ', access_token);
      fetch("/api/v2/planzu", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access_token,
        }
      })
      .then(response => handleErrors(response))
      .then(remote_response => {
        if(remote_response && remote_response.planzu){
          //console.log('in planzuSerices.getPlanzu success. remote_response.planzu is ', remote_response.planzu);
          resolve(remote_response.planzu);
        } else {
          //How can we know planzu does not exist?
          console.log('planzu does not exist. calling createPrincipal.');
          const planzuPromise = planzuServices.createPrincipal(access_token, userProfile);
          planzuPromise.then( new_planzu =>{
            console.log('in planzuServices.setPlanzu success. The planzu is ', new_planzu);
            resolve(new_planzu);
          }).catch(error =>{
            console.log('in getPlanzu call setPlanzu error is ', error);
            reject(error);
          });
        }
      }).catch(error =>{
        console.log('in getPlanzu error is ', error);
        reject(error);
      });
    });
  }
}
planzuServices.createPrincipal = (access_token, userProfile) => {
  return new Promise((resolve, reject) => {
    const pendingPrincipal = {
      user_name: userProfile.sub
    }
    const principal_stringified = JSON.stringify(pendingPrincipal)
    fetch('/api/v2/planzu', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token,
      },
      body: principal_stringified
    })
    .then(response => handleErrors(response))
    .then(new_principal => {
      resolve(new_principal)
    }).catch(error=>{
      console.log('in setPrincipal error is ', error);
      reject(error);
    });
  });
}
planzuServices.updatePlanzu = (access_token, userProfile, planzu) => {
  return new Promise((resolve, reject) => {
   //console.log('in planzuServices.updatePlanzu access_token is ', access_token);
   // console.log('in planzuServices.updatePlanzu planzu is ', planzu);
    const planzu_stringified = planzu ? JSON.stringify(planzu):'';
    fetch('/api/v2/planzu', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token,
      },
      body: planzu_stringified
    })
    .then(response => handleErrors(response))
    .then(new_planzu => {
      resolve(new_planzu)
    }).catch(error=>{
      console.log('in updatePlanzu error is ', error);
      reject(error);
    });
  });
}
planzuServices.betaSignup= (email, headline) =>{
    return new Promise((resolve, reject) => {
    fetch('/api/v2/beta', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email:email, headline:headline})
    })
    .then(response => handleErrors(response))
    .then(email_response => {
      resolve(email_response)
    }).catch(error=>{
      console.log('in betaSignup error is ', error);
      reject(error);
    });
  });
}
planzuServices.sendComment= (comment, headline) =>{
    return new Promise((resolve, reject) => {
    fetch('/api/v2/comment', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({comment:comment, headline:headline})
    })
    .then(response => handleErrors(response))
    .then(comment_response => {
      resolve(comment_response)
    }).catch(error=>{
      console.log('in sendComment error is ', error);
      reject(error);
    });
  });
}
const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
}

export default planzuServices;